window.imoveisVisiveis = {};

window.setimoveisVisiveis = json => {
	if(imoveisVisiveis){
		imoveisVisiveis = json;
	}
};

window.ajaxAsync = async (url, data) => {

	var result = [];
	for(var i in data){
		result.push([i]+'='+data [i]);
	}

	try {

		return fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type':'application/x-www-form-urlencoded'
			},
			body: result.join('&')
		}).then(resposta => {

			let res = resposta.clone().json();
		
			return res;

		}).catch (error => {
				console.error(error)
			}
		);

	} catch (erro) {
		console.error('ERROW: ' + erro);
	}
};

/* Função responsável por alterar a imagem da miniatura do imóvel quando é clicado nos botões left ou right */
window.miniaturaChangeImg = function(miniatura_id, acao = 1){

	var imagemPrincipal = Boss.getById(miniatura_id);
	var imo_codigo = miniatura_id.split('-')[1];
	var btnleft = Boss.getById('btnleft-' + imo_codigo);
	var btnright = Boss.getById('btnright-' + imo_codigo);

	if(imagemPrincipal){
		var current = imoveisimagens[imo_codigo][0];
		var atual = imagemPrincipal.getAttribute('data-imgatual');

		var novaImagem = current;
		var nImagem = 0;

		/* acao = 1 === DIREITA */
		if(acao == 1){

			var maior = (Number(atual) + 1);

			if(imoveisimagens[imo_codigo][maior]){

				if((Number(imoveisimagens[imo_codigo].length) - 1) == maior){
					novaImagem = imoveisimagens[imo_codigo][maior];
					nImagem = maior;

					btnright.classList.add('hidden');
					btnleft.classList.remove('hidden');

					return false;
				}else{

					btnright.classList.remove('hidden');
					btnleft.classList.remove('hidden');
				}

				novaImagem = imoveisimagens[imo_codigo][maior];
				nImagem = maior;
			}

		}else{
		/* acao = 0 === ESQUERDA */

			var menor = (Number(atual) > 0 ? Number(atual) - 1 : 0);
		
			if(imoveisimagens[imo_codigo][menor]){
				novaImagem = imoveisimagens[imo_codigo][menor];
				nImagem = menor;

				btnright.classList.remove('hidden');
				btnleft.classList.remove('hidden');
			}

			if(menor == 0){
				btnright.classList.remove('hidden');
				btnleft.classList.add('hidden');
			}
		}

		/* Altera a data-imgatual Da IMAGEM e o SRC da mesma */
		imagemPrincipal.setAttribute('data-imgatual', nImagem);
		imagemPrincipal.src = jsdominioimg + '/imoveis/thumb/' + novaImagem;
	}
};

/* Recupera todas as imagens dos imóveis disponíveis */
window.getImovelImagens = e => {

	Boss.ajax({
		'url': '/imoveis/getImovelImagens',
		'data': {
			'getimagens': 'getimagens',
			'imoveisVisiveis': JSON.stringify(imoveisVisiveis)
		},
		'dataType': 'json',
		'done': rtn => {

			if(rtn.r == 'ok'){
				imoveisimagens = rtn.info;

				/* PRELOADER */
				for(var x in rtn.info){

					for(var y in rtn.info[x]){

						var img = document.createElement('img');
						img.src = jsdominioimg+'/imoveis/thumb/'+rtn.info[x][y];
					}
				}
			}
		},
		error: rtn => {
			Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
		}
	});
}

window.share = function(rede, link){

	let url = 'mobile';
	if(Math.max(document.documentElement.clientWidth, window.innerWidth || 0) > 1100){
		url = 'pc';
	}

	if(rede == 'facebook'){

		if(url == 'mobile'){

			window.open("https://www.facebook.com/share.php?u="+link, '_blank');
		}else{

			window.open("https://www.facebook.com/share.php?u="+link, '_blank', 'width=800, height=600, toolbar=no, top=50, left=50');
		}
	}	

	if(rede == 'whatsapp'){

		if(url == 'mobile'){

			window.open("whatsapp://send?text="+link, '_blank');
		}else{

			window.open("https://web.whatsapp.com/send?text="+link, '_blank');
		}
	}

	if(rede == 'twitter'){

		if(url == 'mobile'){

			window.open("https://twitter.com/home?status="+link, '_blank');
		}else{
			window.open("https://twitter.com/home?status="+link, '_blank', 'width=800, height=600, toolbar=no, top=50, left=50');
		}
	}
};

window.imovelImprimirTipo = imo_codigo => {

	var pathurl = `${jsdominio}/imoveis/imprimir/?id=${imo_codigo}`;
	window.open(pathurl);
}

/* PC */
window.toggleMenu = function(){

var nav = Boss.getById('nav-menu');

/* Se houver o elemento NAV, check de o menu está fechado e ABRE, se tiver aberto FECHA */
nav ? nav.classList.contains('nav_open') ? closeMenu() : openMenu() : false;
};

window.openMenu = function(){

var nav = Boss.getById('nav-menu');

/* PARA VERSÃO PC */
if(nav){
	nav.classList.add('nav_open');
	nav.classList.remove('nav_close');

	document.querySelector('.img-btn-menu') ? document.querySelector('.img-btn-menu').setAttribute('class', 'img-btn-menu icl ic-times') : false;
}
};

window.closeMenu = function(){
var nav = Boss.getById('nav-menu');

if(nav && nav.classList.contains('nav_open') === true){
	nav.classList.add('nav_close');
	nav.classList.remove('nav_open');
	document.querySelector('.img-btn-menu') ? document.querySelector('.img-btn-menu').setAttribute('class', 'img-btn-menu icl ic-bars') : false;
}
};

window.imovelImprimir = imo_codigo => {

	if(typeof(imo_codigo) == 'undefined'){
		return false;
	}

	let html = `
		<div class="boss-dialog-area">
			<h1>Imprimir Imóvel ID: ${imo_codigo}</h1>
			<p>Escolha o tipo para impressão.</p>
			<div class="site-space-1"></div>
			<div class="block width-50">
				<button name="button"  class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo});"><i class="icl ic-file-image"></i> Retrato Simples</button>
			</div><div class="block width-50">
				<button name="button"  class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo});"><i class="icl ic-file-image"></i> Retrato Completa</button>
			</div><div class="block width-50 align-middle">
				<button name="button"  class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo});"><i class="icl ic-image"></i> Paisagem Simples</button>
			</div><div class="block width-50 align-middle">
				<button name="button"  class="b b-padrao width-100" onclick="imovelImprimirTipo(${imo_codigo});"><i class="icl ic-image"></i> Paisagem Completa</button>
			</div>
			<div class="site-space-1"></div>
		</div>
	`;

	Boss.dialog.open({
		'invisible': false,
		'close': true,
		'html': html
	});
}

Boss.evts.add('scroll', document, evts => {

	if(window.scrollY > (document.documentElement.clientHeight || 0)){
		document.querySelector('.b-tohome') ? document.querySelector('.b-tohome').classList.remove('hidden') : null;
	}else{
		document.querySelector('.b-tohome') ? document.querySelector('.b-tohome').classList.add('hidden') : null;
	}
});



!(function (window) {
	var $q = function (q, res) {
		if (document.querySelectorAll) {
			res = document.querySelectorAll(q);
		} else {
			var d = document,
				a = d.styleSheets[0] || d.createStyleSheet();
			a.addRule(q, "f:b");
			for (var l = d.all, b = 0, c = [], f = l.length; b < f; b++) l[b].currentStyle.f && c.push(l[b]);
			a.removeRule(0);
			res = c;
		}
		return res;
	},
	addEventListener = function (evt, fn) {
		window.addEventListener ? this.addEventListener(evt, fn, true) : window.attachEvent ? this.attachEvent("on" + evt, fn) : (this["on" + evt] = fn);
	},
	_has = function (obj, key) {
		return Object.prototype.hasOwnProperty.call(obj, key);
	};
function loadImage(el, fn) {
	var img = new Image(),
		src = el.getAttribute("data-src");
		img.onload = function () {
			if (!!el.parent) {
				el.parent.replaceChild(img, el);
			} else {
				el.src = src;
			}
			if (fn) {
				fn();
			}
		};
	img.src = src;
}
function elementInViewport(el) {
	var rect = el.getBoundingClientRect();
	return rect.top >= 0 && rect.left >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight);
}
window.images_site = new Array(),
	query = $q("img.lazy"),
	processScroll = function () {
		
		for (var i = 0; i < images_site.length; i++) {
			if (elementInViewport(images_site[i])) {
				loadImage(images_site[i], function () {
					images_site.splice(i, i);
				});
			}
		}
	};
for (var i = 0; i < query.length; i++) {
	images_site.push(query[i]);
}
processScroll();
addEventListener("scroll", processScroll);
})(this);


/* CARREGA APENAS IMAGENS */
window.loadingLazy = {
	images: new Array(),
	selectorAll: "img.lazy",
	query: document.querySelectorAll(this.selectorAll),
	loadImage: (el, fn) => {
		
		var img = new Image();
		if(el.getAttribute("data-src")){
			var	src = el.getAttribute("data-src");
			img.onload = f => {
				el.src = src;
				if(fn){
					fn();
				}
				el.classList.remove('lazy');
				el.removeAttribute('data-src');
			};
			img.src = src;
			img.removeAttribute('data-src');
			img.classList.remove('lazy');
		}
			
	},
	elementInViewport: (el) => {
		var top = el.offsetTop;
		var left = el.offsetLeft;
		var width = el.offsetWidth;
		var height = el.offsetHeight;
		
		while(el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}
		
		return (
			top < (window.pageYOffset + window.innerHeight) &&
			left < (window.pageXOffset + window.innerWidth) &&
			(top + height) > window.pageYOffset &&
			(left + width) > window.pageXOffset
		);
	},
	processScroll: f => {

		/* Verifica se já foi mostrado todas as img / bg */
		/* console.log(document.body.querySelectorAll('.lazy')); */
		if(document.body.querySelectorAll('.lazy').length <= 0){
			/* console.log('PRONTO, JA CARREGOU TUDO AS IMG!'); */
		};

		for (var i = 0; i < loadingLazy.images.length; i++) {
			
			if (loadingLazy.elementInViewport(loadingLazy.images[i])) {
				
				loadingLazy.loadImage(loadingLazy.images[i], () => {
					loadingLazy.images.splice(i, i);
				});
			}
		}
	},
	consultaImagens: elemento_escuta => {
		loadingLazy.query = elemento_escuta.querySelectorAll(loadingLazy.selectorAll);

		for (var i = 0; i < loadingLazy.query.length; i++) {
			loadingLazy.images.push(loadingLazy.query[i]);
		}
	},
	listener: f => {
		window.addEventListener("scroll", loadingLazy.processScroll, true);
	},
	push: elemento_escuta => {
		window.removeEventListener("scroll", loadingLazy.processScroll, true);
		loadingLazy.images = new Array();
		loadingLazy.consultaImagens(elemento_escuta);
		loadingLazy.processScroll();
		loadingLazy.listener();
	},
	init: elemento_escuta => {
		loadingLazy.consultaImagens(elemento_escuta);
		loadingLazy.processScroll();
		loadingLazy.listener();
	}
};

loadingLazy.init(document.body);