class Busca {

	constructor(inputs, id_render_busca){

		// Primeiros Atributos, tudo o que a classe for receber
		this.inputs = inputs;
		this.id_render_busca = id_render_busca;
		this.render_element = document.getElementById(this.id_render_busca);
		this.rules(); // Valida esses parametros

		// Atributos internos da  classe
		this.payload = window.location.href.split('payload=')[1];
		this.valores = new Object();
		this.endpoint = '/imoveis/buscar/?payload=';
		this.endpoint_back = '';

		this.selectmultiples = [];
		this.componentsinputs = [];
		this.instancia_nome;

		for(var i in inputs){
			this.valores[inputs[i]] = '';
		}
		
		/* beforeunload EVENT  */
		Boss.evts.add('popstate', window, function(evts){

			evts.cancelBubble = true;

			if(evts.stopPropagation){
				evts.stopPropagation();
			}

			if(evts.preventDefault){
				evts.preventDefault();
			}

			var payload = window.location.href.split('payload=')[1];

			if(typeof(payloadBusca) !== 'undefined'){
				payloadBusca.setPayload = payload;
				payloadBusca.send(true);
			}
		});
	}

	addComponentsinputs(input){
		this.componentsinputs.push(input);
	}

	addSelectmultiple(selectmultiples){
		this.selectmultiples.push(selectmultiples);
	}

	// Função que valida os parâmetros e da classe
	rules(){

		if(production === true){
			return false;
		}

		if(typeof(this.id_render_busca) == 'undefined'){
			console.warn('Ops, vou precisar que você informe o ID do elemento para renderizar a busca.');
			return false;
		}

		if(!this.render_element){
			console.warn('Ops, parece que o ID elemento que você informou para renderizar a busca não existe.');
			return false;
		}

		if(typeof(this.inputs) == 'undefined'){
			console.warn('Ops, vou precisar que você informe os inputs. É uma lista simples ["dor", "vag", "cod"].');
			return false;
		}
	}

	add(valor, idElemento){

		this.setValueInput(valor, idElemento);

		this.mountPayload();
	}

	set setPayload(payload){
		this.payload = payload;
		wqddwqgqioq.href = this.payload;
	}

	setValueInput(valor, idElemento){

		if(typeof(this.valores[idElemento]) !== 'undefined'){

			// Se for um Array || lista
			if(valor instanceof Array){

				this.valores[idElemento] = valor.join(',');
			}else{
			// Se for uma string
				this.valores[idElemento] = valor;
			}
		}

	}

	adicionaValoresPayload(){
		if(typeof(payloadBusca) != 'undefined'){
			var temp = [];
			for (let i = 0; i < payloadBusca.selectmultiples.length; i++) {
				let data = payloadBusca.selectmultiples[i];
				
				temp[i] = [payloadBusca.selectmultiples[i].values,payloadBusca.selectmultiples[i].alias];
				var temp2 = '';
				if(payloadBusca.selectmultiples[i].values != ''){

					for(var y in payloadBusca.selectmultiples[i].values){
						temp2 += payloadBusca.selectmultiples[i].values[y]+',';
					}
					payloadBusca.valores[payloadBusca.selectmultiples[i].alias] = temp2;
				}
			}

			for (let i = 0; i < payloadBusca.componentsinputs.length; i++) {
				let data = payloadBusca.componentsinputs[i];
				
				temp[i] = [payloadBusca.componentsinputs[i].value,payloadBusca.componentsinputs[i].id];
				var temp2 = '';
				if(payloadBusca.componentsinputs[i].value != ''){
					if(payloadBusca.componentsinputs[i]){
						payloadBusca.valores[payloadBusca.componentsinputs[i].id] = payloadBusca.componentsinputs[i].value;
					}
				}
			}
		}
	}

	// Monta o PayLoad
	mountPayload(){

		var temp = '';
		for(var i in this.valores){
			temp += '&' + i + '=' + this.valores[i];
		}
		
		this.payload = temp;
	}

	static checkboxValues(filtros, elemento_pai_filtros){

		if(!elemento_pai_filtros){
			return false;
		}

		var inputs = elemento_pai_filtros.querySelectorAll('input[type="checkbox"]');

		var infs = filtros.inf;

		if(infs !== false){

			for(var i = 0; i < inputs.length; i++){

				inputs[i].checked = false;
				if(infs[inputs[i].id.replace('comp-', '')]){
					inputs[i].checked = true;
				}
			}
		}
	}

	static selectmultiplesValues(filtros, selectmultiples){

		for(var i = 0; i < selectmultiples.length; i++){
			var valor = [];
			if(filtros[selectmultiples[i]['alias']] !== false){
				valor = filtros[selectmultiples[i]['alias']];
			}

			selectmultiples[i].setValues(valor);
		}
	}
	
	static componentinputsValues(filtros, componentsinputs){

		function isDict(v){
			return typeof v==='object' && v!==null && !(v instanceof Array) && !(v instanceof Date);
		}

		for(var i = componentsinputs.length - 1; i >= 0; i--){

			if(componentsinputs[i] && filtros[componentsinputs[i].id] !== false){
				var valorInput = '';

				if(isDict(filtros[componentsinputs[i].id])){
					for(var x in filtros[componentsinputs[i].id]){
						valorInput += filtros[componentsinputs[i].id][x] + ',';
						
					}
					
					if(valorInput.substring(valorInput.length-1) == ","){
						valorInput = valorInput.replace(' ','');
						valorInput = valorInput.substr(0, valorInput.length - 1);
					}

				}else{
					valorInput = filtros[componentsinputs[i].id];
				}

				componentsinputs[i].value = valorInput;
			}
		}
	}

	static tituloBusca(titulo_busca_id, quantidade, href = false){
		let quantidadeImoveis = quantidade;
		let titulo_busca = Boss.getById(titulo_busca_id);

		titulo_busca.innerHTML = '';
		if(titulo_busca){

			titulo_busca.innerHTML = quantidade;

			/* if(quantidadeImoveis == 0){
				titulo_busca.innerHTML = 'Nenhum imóvel foi encontrado.';
				document.getElementById('render-busca').innerHTML = '';
			}else{

				var encontrado = 'encontrado';
				var imovel = 'imóvel';
				if(quantidadeImoveis > 1){
					encontrado = 'encontrados';
					imovel = 'imóveis';
				}
				titulo_busca.innerHTML = '<strong> ' + quantidadeImoveis + ' '+imovel+'</strong> '+encontrado+'.' 
			} */
		}
	}

	static positionAtTop(elemento){
		let posicao = 0;
		if(elemento.offsetParent){
			do{
				posicao += elemento.offsetTop;
			} while (elemento = elemento.offsetParent);
		}
		return posicao;
	}

	static request(endpoint, pushstate, selectmultiples, componentsinputs, href = false){

		if(document.getElementById('titulo_busca')){
			document.getElementById('titulo_busca').innerHTML = 'Aguarde... <i class="icl ic-spinner-third rotating"></i> ';
		}
		
		Boss.ajax({
			'url': endpoint,
			'data': {'push': 'push'},
			'dataType': 'json',
			'done': function(rtn){

				// Atualiza o título da Busca
				Busca.tituloBusca('titulo_busca', rtn.titulo, href);

				/* Atualiza os campos do SelectMultiple */
				Busca.selectmultiplesValues(rtn.filtros, selectmultiples);

				/* Atualiza os campos do Input */
				Busca.componentinputsValues(rtn.filtros, componentsinputs);

				/* Atualiza os campos Checkbox */
				Busca.checkboxValues(rtn.filtros, Boss.getById('busca-filtros'));

				if(!pushstate){
					history.pushState({}, '', endpoint);
				}

				if(document.getElementById('render-busca')){
					document.getElementById('render-busca').innerHTML = rtn.html;
				}

				if(typeof(loadingLazy.push) !== 'undefined'){
                	loadingLazy.push(document.getElementById('content'));
				}

			},
			error: function(rtn){
				Boss.warning({message: 'Falha ao enviar, tente novamente em alguns segundos!'});
			}
		});
	}

	// Função que faz o request para a busca
	// DEPOIS que enviar o payload, fechar a busca && filtro
	// O parametro HREF, é para os casos em que vai utilizar a busca mas não está no controlador da busca aonde fica o render.
	// Isso quer dizer que não podemos utilizar o pushstate porque no retorno do ajax não tem aonde ele exibir os resultados.
	// No formado do site SEM PUSH HISTORY, não é possível alterar a pagina sem um refresh, sendo assim perdendo a instancia da busca.
	// Quando for utilizar o método send() FORA da busca em outro controlador qualquer.. Usa-se send(true, true) para não fazer o request
	// E simplesmente redimencionar para a página da busca com os filtros prontos.
	send(pushstate = false, href = false, time = 0){

		var endpoint = this.endpoint + '' + this.payload;
		wqddwqgqioq.href = endpoint;
		var selectmultiples = this.selectmultiples;
		var componentsinputs = this.componentsinputs;

		if(href){
			history.pushState({}, '', endpoint);
			return false;
		}

		Boss.delayPersistent2(function(){
			Busca.request(endpoint, pushstate, selectmultiples, componentsinputs, href);
		}, time, 'request-ajax');
	}
}